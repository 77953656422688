import mondaySdk from 'monday-sdk-js';

const monday = mondaySdk();

monday.setApiVersion('2024-10');

export async function getSessionToken() {
  return ((await monday.get('sessionToken')) as any).data;
}

export async function mondayApi(query: string, ...rest: any[]) {
  try {
    return await monday.api(query, ...rest);
  } catch (err) {
    console.log('Failed to execute monday api query', query, err, ...rest);
    throw err;
  }
}

export default monday;
